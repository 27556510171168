/* eslint-disable react/jsx-no-comment-textnodes */

import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { Avatar } from '@daouoffice/ui/lib/foundation/Avatar';
import { getRandomColor } from '@dop-ui/react/shared/lib/utils';

import { NodeProps } from './interface';
import { useOrgChartContext } from './Provider';
import { DEPARTMENT } from './constants';

export interface Props {
  node: NodeProps;
  isActive: boolean;
  updateNodes: (node: NodeProps) => void;
}

const MemberNode = ({ node, isActive, updateNodes }: Props) => {
  const context = useOrgChartContext();
  const { onNodeClick } = context.actions;
  const { useMultiSelect, uuid, usePresence, type } = context.state;
  const isChecked = node.selected;

  const toggleSelect = () => {
    updateNodes(node);
  };

  const clickNode = () => {
    console.log('멤버 프로필 오픈');
    if (onNodeClick && type !== DEPARTMENT)
      onNodeClick({ ...node, userId: node.userId as number });
  };

  const isMaster = node.assistantHead || node.departmentHead;

  return (
    <>
      <div
        onClick={clickNode}
        className={`member${isActive ? ' active' : ''}`}
        role="button"
        aria-hidden
      >
        <Avatar
          size={40}
          alt={node.name}
          src={node.profileImageUrl}
          backgroundColor={getRandomColor(node.userId)}
        />
        {isMaster && (
          <i className="icon master">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="18" height="18" rx="9" fill="#0F1419"></rect>
              <path
                d="M5.815 12.68V6.32001H7.155L8.495 9.54321C8.675 9.958 8.825 10.3901 8.995 10.8221H9.035C9.215 10.3901 9.355 9.958 9.515 9.54321L10.845 6.32001H12.185V12.68H11.115V9.54321C11.115 8.96425 11.205 8.15196 11.265 7.573H11.225L10.625 9.04202L9.345 12.0751H8.625L7.345 9.04202L6.755 7.573H6.715C6.775 8.15196 6.865 8.96425 6.865 9.54321V12.68H5.815Z"
                fill="white"
              ></path>
            </svg>
          </i>
        )}
        <div className="info">
          <div className="main">
            <span className="name body_medium regular" title={node.name}>
              {node.name}
            </span>
          </div>
          {useMultiSelect && (
            <CheckBox
              id={`${node.nodeType}_${node.userId}_${uuid}`}
              onChange={toggleSelect}
              defaultChecked={isChecked}
            ></CheckBox>
          )}
        </div>
        {usePresence && (
          <span
            className="ic ic_state_on away"
            style={{
              position: 'absolute',
              top: '0px',
              width: '8px',
              height: '8px',
              // background: presence.status ? 'lightgreen' : 'gray',
            }}
          ></span>
        )}
      </div>
    </>
  );
};

export default MemberNode;
